<template>
  <div class="dashboard-home pt-3">
    <div class="row justify-content-center">
      <div class="col-md-4 col-lg-3 mb-2">
        <img id="logo" class="img-fluid" :src="require('@/assets/logo.png')" />
        <p><strong>LOGO</strong></p>
      </div>
      <div class="w-100"></div>
      <div class="col mb-2 text-end pe-5">
        <button class="btn btn-warning" v-if="!editing" @click="toggle">
          <font-awesome-icon icon="edit" /> แก้ไข
        </button>
        <button
          class="btn btn-primary"
          v-if="editing"
          @click="updateInformation"
        >
          <font-awesome-icon icon="save" /> บันทึก
        </button>
      </div>
      <div class="w-100"></div>
      <div class="col-md-12 mb-2">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="floatingSchool"
            placeholder="name@example.com"
            autocomplete="false"
            :disabled="!editing"
            v-model="information.school"
          />
          <label for="floatingSchool">ชื่อโรงเรียน</label>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="floatingDirector"
            autocomplete="false"
            :disabled="!editing"
            v-model="information.director"
          />
          <label for="floatingDirector">ผู้อำนวยการ</label>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="floatingHeadAcademic"
            autocomplete="false"
            :disabled="!editing"
            v-model="information.head_academic"
          />
          <label for="floatingHeadAcademic">หัวหน้าวิชาการ</label>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="floatingRegistra"
            autocomplete="false"
            :disabled="!editing"
            v-model="information.registra"
          />
          <label for="floatingRegistra">นายทะเบียน</label>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="floatingHeadEval"
            autocomplete="false"
            :disabled="!editing"
            v-model="information.head_eval"
          />
          <label for="floatingHeadEval">หัวหน้างานวัดและประเมินผล</label>
        </div>
      </div>
      <div class="col-md-12 mb-2">
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="floatingAddress"
            autocomplete="false"
            :disabled="!editing"
            v-model="information.address"
          />
          <label for="floatingAddress">ที่อยู่โรงเรียน</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../db";
import "firebase/database";
const dbRefInfo = firebase.database().ref("information");

export default {
  name: "DashboardHome",
  data: function () {
    return {
      information: {
        school: "",
        director: "",
        head_academic: "",
        registra: "",
        address:"",
        head_eval:""
      },
      editing: false,
    };
  },
  mounted: async function () {
    await dbRefInfo.on("value", (snapshot) => {
      const data = snapshot.val();
      this.information = data;
    });
  },
  methods: {
    toggle: function () {
      if (this.editing) {
        this.editing = false;
      } else {
        this.editing = true;
      }
    },
    updateInformation: function () {
      dbRefInfo.set(this.information);
      this.toggle();
    },
  },
};
</script>

<style>
</style>